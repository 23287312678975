import { Link } from "gatsby"
import React, { useEffect } from "react"
import { useLocation } from "@reach/router"

const Header = ({ menuState, setMenuState }) => {
  const location = useLocation()
  useEffect(() => {
    setMenuState(false)
  }, [location])
  return (
    <header>
      <div className="container fluid">
        <div className="header-inner">
          <Link activeClassName="active" to="/">
            <span style={{ fontSize: 18 }}>Paaolms°</span>
          </Link>
          <nav>
            <Link activeClassName="active" to="/insights">
              Work
            </Link>
            <Link activeClassName="active" to="/profile">
              About
            </Link>
            <Link activeClassName="active" to="/tribune">
              Tribune
            </Link>
            <Link activeClassName="active" to="/showreel">
              Showreel
            </Link>
            <Link activeClassName="active" to="/skills">
              Skills
            </Link>
            <Link activeClassName="active" to="/connect">
              Insights
            </Link>
            <Link activeClassName="active" to="https://www.blog.paaolms.com">
              Blog
            </Link>
          </nav>
          <div
            onClick={() => setMenuState(!menuState)}
            className="hamburger-menu"
          >
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
