// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-de-moi-js": () => import("./../../../src/pages/à-propos-de-moi.js" /* webpackChunkName: "component---src-pages-a-propos-de-moi-js" */),
  "component---src-pages-accomplishments-js": () => import("./../../../src/pages/accomplishments.js" /* webpackChunkName: "component---src-pages-accomplishments-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-prestations-js": () => import("./../../../src/pages/prestations.js" /* webpackChunkName: "component---src-pages-prestations-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-showreel-js": () => import("./../../../src/pages/showreel.js" /* webpackChunkName: "component---src-pages-showreel-js" */),
  "component---src-pages-skills-js": () => import("./../../../src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */),
  "component---src-pages-tribune-js": () => import("./../../../src/pages/tribune.js" /* webpackChunkName: "component---src-pages-tribune-js" */)
}

