module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/globoconsulting/Documents/•1/Paaolms/caseStudy/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#191919","theme_color":"#191919","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"995086659a52a3f5740f77e22f101520"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WWSQX2S","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"&url=https%3A%2F%2Fwww.paaolms.com","gtmPreview":"&gtm_auth=px5cGhoGVA7PQ1NdPlAnUg&gtm_preview=env-1&url=https%3A%2F%2Fwww.paaolms.com","dataLayerName":"Paaolms","routeChangeEventName":"routeChangeEventName","enableWebVitalsTracking":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-196914447-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
